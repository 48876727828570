import logo from 'assets/logo.png';
import tawkLogo from 'assets/tawk-site.png';
import { useAuth } from 'lib/auth';
import { useAuthorization, ROLES } from 'lib/authorization';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { UserIcon, HomeIcon, MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import { CiViewTimeline } from 'react-icons/ci';
import {
  FileText,
  LogOut,
  MessageSquare,
  Users,
  Film,
  Book,
  BookOpen,
  Briefcase,
  AlignJustify,
  List,
  File,
  Circle,
  Folder,
  Layers,
  Archive,
  Link2,
  Disc,
  DownloadCloud,
  Trello,
} from 'react-feather';
import { NavLink, Link } from 'react-router-dom';

const SideNavigation = () => {
  const { checkAccess } = useAuthorization();
  const navigation = [
    { name: 'Dashboard', to: '.', icon: HomeIcon },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Banners',
      to: './banners',
      icon: Book,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'News',
      to: './news',
      icon: BookOpen,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Multimedia',
      to: './multimedia',
      icon: Film,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Press Release',
      to: './press-release',
      icon: Briefcase,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Events',
      to: './events',
      icon: AlignJustify,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Reports',
      to: './reports',
      icon: List,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Timetables',
      to: './timetables',
      icon: CiViewTimeline,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Partners',
      to: './partners',
      icon: FileText,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Publications',
      to: './publications',
      icon: File,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Counter',
      to: './counter',
      icon: Circle,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Downloads & Resources',
      to: './downloads-resources',
      icon: DownloadCloud,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Timeline',
      to: './timeline',
      icon: Trello,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Circulars',
      to: './circulars',
      icon: Folder,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Summary Sheets',
      to: './summary-sheets',
      icon: Layers,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'EMIS Manuals',
      to: './emis-manuals',
      icon: Trello,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Other Documents',
      to: './documents',
      icon: Archive,
    },
    // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
    //   name: 'Industrial Training',
    //   to: './industrial-training',
    //   icon: FileText,
    // },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Trainee Registration',
      to: './trainees',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Alumni',
      to: './alumni',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'UBTEB Links',
      to: './ubteb-links',
      icon: Link2,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Accredited Centres',
      to: './accredited-centres',
      icon: Disc,
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Acts',
      to: './acts',
      icon: FileText,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'People',
      to: './people',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Jobs',
      to: './jobs',
      icon: FileText,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Tenders',
      to: './tenders',
      icon: FileText,
    },
  ].filter(Boolean);

  return (
    <>
      {navigation.map((item, index) => (
        <div key={index}>
          {item.heading && (
            <p
              key={index}
              className="px-3 py-4 my-2 text-xs font-sm text-gray-200 uppercase tracking-wider"
            >
              {item.heading}
            </p>
          )}
          <NavLink
            end={index === 0}
            key={item.name}
            to={item.to}
            className={clsx(
              'text-white  hover:text-green-500',
              'group flex items-center px-2 py-2 text-base font-medium rounded-md '
            )}
          >
            <div className="flex flex-col">
              {/* <p className=" bg-gray-300 font-extrabold ">{item.heading}</p> */}

              <div className="flex">
                <item.icon
                  className={clsx(
                    'text-white group-hover:text-green-500',
                    'mr-4 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  );
};

const UserNavigation = () => {
  const { user, logout } = useAuth();

  const userNavigation = [
    {
      name: 'Profile',
      icon: <UserIcon className="h-4" />,
      to: './profile',
    },
    {
      name: 'Settings',
      icon: <UserIcon className="h-4" />,
      to: './settings',
    },
    {
      name: 'Sign out',
      icon: <LogOut className="h-4" />,
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean);

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs  bg-white border border-gray-200 p-2 flex items-center text-sm rounded-lg focus:none">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-4 w-4 rounded-full" />{' '}
              <span className="mx-2">{user.name}</span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 dark:bg-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        `flex flex-row items-center`,
                        active ? 'bg-gray-100 dark:bg-gray-700' : '',
                        'block px-4 py-2 text-sm text-gray-700 dark:text-gray-400'
                      )}
                    >
                      {item.icon}
                      <span className="ml-2">{item.name}</span>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

MobileSidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};
const Sidebar = () => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-60">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 px-4">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 bg-blue-800 space-y-1">
              <SideNavigation />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to=".">
      <img className="h-10 w-auto" src={logo} alt="Workflow" />
    </Link>
  );
};

export const MainLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 items-center flex-shrink-0 flex h-16 bg-white shadow dark:bg-gray-800">
          <div className="px-10 text-xs text-gray-300 truncate overflow-hidden">
            © Copyright 2023 - Uganda Business and Technical Examinations Board © 2023,{' '}
            <a href="https://jast-tech.com" target="_blank" rel="noreferrer">
              Built by Jast Tech
            </a>
          </div>
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <a href="https://dashboard.tawk.to/login" target="_blank">
                <div className="relative">
                  <img src={tawkLogo} alt="tawk.io" className="h-8 w-8" />
                </div>
              </a>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none dark:bg-gray-800">
          {children}
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
