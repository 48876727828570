import { Spinner, UnderDevelopment } from 'components/Elements';
import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { Link as NavLink } from 'react-router-dom';
import {
  AlignJustify,
  Archive,
  Book,
  BookOpen,
  Briefcase,
  Disc,
  DownloadCloud,
  File,
  Film,
  Folder,
  Layers,
  Link,
  List,
  Sidebar,
  Trello,
  Users,
} from 'react-feather';
import { MdClose, MdEmail } from 'react-icons/md';
import { useStatistics } from '../api/getStatistics';
import { Card } from '../components/Card';
import { useState } from 'react';

export const Dashboard = () => {
  const { user } = useAuth();

  let feat = localStorage.getItem('newFeature');

  console.log(feat);

  const [newFeature, setNewFeature] = useState(feat);

  const statisticsQuery = useStatistics();

  if (statisticsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!statisticsQuery.data) return null;

  return (
    <ContentLayout title="Dashboard">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-3">
          {newFeature === null ? (
            <div
              class="bg-orange-100 hover:bg-orange-200 border-l-4 my-2 rounded-md border-orange-500 text-orange-700 p-4"
              role="alert"
            >
              <div className="flex justify-between items-center">
                <p class="font-bold">New Feature 😃</p>
                <button
                  onClick={() => {
                    setNewFeature('viewed');
                    localStorage.setItem('newFeature', 'viewed');
                  }}
                >
                  <MdClose className="h-4" />
                </button>
              </div>
              <p>
                You can now upload timetables from the panel. Go to{' '}
                <NavLink to="./timetables" className="underline">
                  Timetables
                </NavLink>
                .
              </p>
            </div>
          ) : (
            <div className="my-2">
              <p className="text-gray-500 text-lg font-bold"> Hello 👋🏼 {user.name}</p>
            </div>
          )}
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2 mb-2">
            <Card
              link="./banners"
              icon={<Book className="h-5 text-gray-600" />}
              content={statisticsQuery.data.banners}
              label="Banners"
            />
            <Card
              link="./news"
              icon={<BookOpen className="text-gray-600" />}
              content={statisticsQuery.data.posts}
              label="News"
            />
            <Card
              link="./multimedia"
              icon={<Film className="text-gray-600" />}
              content={statisticsQuery.data.multimedias}
              label="Multimedia"
            />
            <Card
              link="./press-release"
              icon={<Briefcase className="text-gray-600" />}
              content={statisticsQuery.data.pressReleases}
              label="Press Release"
            />
            <Card
              link="./events"
              icon={<AlignJustify className="text-gray-600" />}
              content={statisticsQuery.data.events}
              label="Events"
            />
            <Card
              link="./reports"
              icon={<List className="text-gray-600" />}
              content={statisticsQuery.data.reports}
              label="Reports"
            />
            <Card
              link="./publications"
              icon={<File className="text-gray-600" />}
              content={statisticsQuery.data.publications}
              label="Publications"
            />
            <Card
              link="./circulars"
              icon={<Folder className="text-gray-600" />}
              content={statisticsQuery.data.circulars}
              label="Circulars"
            />
            <Card
              link="./summary-sheets"
              icon={<Layers className="text-gray-600" />}
              content={statisticsQuery.data.summarySheets}
              label="Summary Sheets"
            />
            <Card
              link="./documents"
              icon={<Archive className="text-gray-600" />}
              content={statisticsQuery.data.otherDocuments}
              label="Documents"
            />
            <Card
              link="./trainees"
              icon={<Users className="text-gray-600" />}
              content={statisticsQuery.data.trainees}
              label="Trainees"
            />
            <Card
              link="./alumni"
              icon={<Users className="text-gray-600" />}
              content={statisticsQuery.data.alumni}
              label="Alumni"
            />
            <Card
              link="./ubteb-links"
              icon={<Link className="text-gray-600" />}
              content={statisticsQuery.data.links}
              label="UBTEB Links"
            />
            <Card
              link="./accredited-centres"
              icon={<Disc className="text-gray-600" />}
              content={statisticsQuery.data.centres}
              label="Accredited Centres"
            />
            <Card
              link="./downloads-resources"
              icon={<DownloadCloud className="text-gray-600" />}
              content={statisticsQuery.data.resources}
              label="Downloads & Resources"
            />
            <Card
              link="./contacts"
              icon={<MdEmail className="text-gray-600" />}
              content={statisticsQuery.data.contacts}
              label="Mailing List"
            />
            <Card
              link="./partners"
              icon={<Sidebar className="text-gray-600" />}
              content={statisticsQuery.data.partners}
              label="Partners"
            />
            <Card
              link="./people"
              icon={<Users className="text-gray-600" />}
              content={statisticsQuery.data.members}
              label="People"
            />
            <Card
              link="./jobs"
              icon={<Archive className="text-gray-600" />}
              content={statisticsQuery.data.jobs}
              label="Jobs"
            />
            <Card
              link="./tenders"
              icon={<Archive className="text-gray-600" />}
              content={statisticsQuery.data.tenders}
              label="Tenders"
            />
          </div>
        </div>
        {/* <div className="border border-gray-300 shadow-lg rounded-lg p-5">
          <div className="flex flex-row items-center justify-between mb-1">
            <span className="text-xs text-gray-700 uppercase">Recent Comments</span>
            <span className="text-gray-400 text-xs">This week</span>
          </div>
          <span className="text-gray-400 text-sm">There are no comments at the moment.</span>
          <hr />
        </div> */}
      </div>
    </ContentLayout>
  );
};
