import { Spinner } from 'components/Elements';
import { MainLayout } from 'components/Layout';
import { lazyImport } from 'utils/lazyImport';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const { BannersRoutes } = lazyImport(() => import('features/banners'), 'BannersRoutes');
const { PostsRoutes } = lazyImport(() => import('features/posts'), 'PostsRoutes');
const { ResourcesRoutes } = lazyImport(() => import('features/resources'), 'ResourcesRoutes');
const { TimetablesRoutes } = lazyImport(() => import('features/timetables'), 'TimetablesRoutes');
const { TimelinesRoutes } = lazyImport(() => import('features/timeline'), 'TimelinesRoutes');
const { CentresRoutes } = lazyImport(() => import('features/centres'), 'CentresRoutes');
const { CountersRoutes } = lazyImport(() => import('features/counter'), 'CountersRoutes');
const { ReportsRoutes } = lazyImport(() => import('features/reports'), 'ReportsRoutes');
const { TendersRoutes } = lazyImport(() => import('features/tenders'), 'TendersRoutes');
const { JobsRoutes } = lazyImport(() => import('features/jobs'), 'JobsRoutes');
const { ActsRoutes } = lazyImport(() => import('features/acts'), 'ActsRoutes');
const { CircularsRoutes } = lazyImport(() => import('features/circulars'), 'CircularsRoutes');
const { TraineesRoutes } = lazyImport(() => import('features/trainees'), 'TraineesRoutes');
const { AlumnisRoutes } = lazyImport(() => import('features/alumni'), 'AlumnisRoutes');
const { PeopleRoutes } = lazyImport(() => import('features/people'), 'PeopleRoutes');
const { PartnersRoutes } = lazyImport(() => import('features/partners'), 'PartnersRoutes');
const { SettingsRoutes } = lazyImport(() => import('features/settings'), 'SettingsRoutes');
const { OtherDocumentsRoutes } = lazyImport(
  () => import('features/otherDocuments'),
  'OtherDocumentsRoutes'
);
const { SummarySheetsRoutes } = lazyImport(
  () => import('features/summarySheets'),
  'SummarySheetsRoutes'
);
const { PublicationsRoutes } = lazyImport(
  () => import('features/publications'),
  'PublicationsRoutes'
);
const { MultimediaPostsRoutes } = lazyImport(
  () => import('features/multimedia'),
  'MultimediaPostsRoutes'
);
const { PressReleasePostsRoutes } = lazyImport(
  () => import('features/pressRelease'),
  'PressReleasePostsRoutes'
);
const { EventsRoutes } = lazyImport(() => import('features/events'), 'EventsRoutes');
const { UBTEBLinksRoutes } = lazyImport(() => import('features/ubteblinks'), 'UBTEBLinksRoutes');
const { Dashboard } = lazyImport(() => import('features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { Users } = lazyImport(() => import('features/users'), 'Users');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/banners/*', element: <BannersRoutes /> },
      { path: '/app/news/*', element: <PostsRoutes /> },
      { path: '/app/accredited-centres/*', element: <CentresRoutes /> },
      { path: '/app/users', element: <Users /> },
      { path: '/app/counter/*', element: <CountersRoutes /> },
      { path: '/app/timeline/*', element: <TimelinesRoutes /> },
      { path: '/app/multimedia/*', element: <MultimediaPostsRoutes /> },
      { path: '/app/events/*', element: <EventsRoutes /> },
      { path: '/app/reports/*', element: <ReportsRoutes /> },
      { path: '/app/publications/*', element: <PublicationsRoutes /> },
      { path: '/app/circulars/*', element: <CircularsRoutes /> },
      { path: '/app/trainees/*', element: <TraineesRoutes /> },
      { path: '/app/people/*', element: <PeopleRoutes /> },
      { path: '/app/partners/*', element: <PartnersRoutes /> },
      { path: '/app/tenders/*', element: <TendersRoutes /> },
      { path: '/app/jobs/*', element: <JobsRoutes /> },
      { path: '/app/acts/*', element: <ActsRoutes /> },
      { path: '/app/alumni/*', element: <AlumnisRoutes /> },
      { path: '/app/summary-sheets/*', element: <SummarySheetsRoutes /> },
      { path: '/app/documents/*', element: <OtherDocumentsRoutes /> },
      { path: '/app/press-release/*', element: <PressReleasePostsRoutes /> },
      { path: '/app/ubteb-links/*', element: <UBTEBLinksRoutes /> },
      { path: '/app/downloads-resources/*', element: <ResourcesRoutes /> },
      { path: '/app/timetables/*', element: <TimetablesRoutes /> },
      { path: '/app/profile', element: <Profile /> },
      { path: '/app/settings', element: <SettingsRoutes /> },
      { path: '/app/', element: <Dashboard /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
